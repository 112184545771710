import React from "react"
import {graphql} from "gatsby"
import {Cards, Hero, SiteMetadata} from "../components"
import {Layout} from "../layouts/Layout"
// import {Analytics} from '@vercel/analytics/react';

export default ({data}) => {
  return (
    <Layout>
      <SiteMetadata
        title="Houtzager Tuinen"
        description="Portfolio Houtzager Advies, Houtzager Tuinen en Routebureau Langs de Linies"
        image={data.hero.url}
      />

      <Hero
        image={data.hero}
        tag="Projecten"
        title="Houtzager Tuinen"
        description="Portfolio Houtzager Advies, Houtzager Tuinen en Routebureau Langs de Linies"
      />

      <Cards nodes={data.items.nodes}/>
      {/*<Analytics/>*/}
    </Layout>
  )
}

export const query = graphql`
  query IndexQuery($tableName: String!) {
    hero: file(relativePath: { eq: "hero-image.jpg" }) {
      ...HeroImageFragment
    }
    items: allAirtable(filter: { table: { eq: $tableName } }) {
      nodes {
        data {
          soortproject
          image {
            ...CardImageFragment
          }
          name
          slug
          summary
        }
      }
    }
  }
`
